import { MIMETYPE_CSV, MIMETYPE_JSON, MIMETYPE_TEXT } from 'cadenza/model/media-type';
import { ajax } from 'cadenza/api-client/api';
import { cadenzaUrl } from 'cadenza/utils/cadenza-url/cadenza-url';
import { download } from 'cadenza/utils/download';
import { logger } from 'cadenza/utils/logging';
import type { WorkbookPerformanceEvent } from 'cadenza/performance-analysis/model/workbook-performance-event-dto';

/* @see net/disy/cadenza/workbook/performance/rest/WorkbookPerformanceAnalysisEndpointController.java */

export function startOrStopPerformanceAnalysis(recording: boolean) {
  navigator.sendBeacon(cadenzaUrl('/performanceAnalysis/status'), String(recording));
}

/**
 * Use the ajax endpoint when we need to wait for the request to be completed, unlike
 * beacon whose typical use case is when unloading the page, and we cannot wait on it.
 */
export function startOrStopPerformanceAnalysisUsingAjax(recording: boolean) {
  return ajax(cadenzaUrl('/performanceAnalysis/status'), {
    method: 'POST',
    data: String(recording),
    contentType: MIMETYPE_TEXT,
  });
}

export function fetchRecordedEvents() {
  return ajax<WorkbookPerformanceEvent[]>('/performanceAnalysis/events', { headers: { accept: MIMETYPE_JSON } });
}

export function downloadRecordedEvents() {
  return download(cadenzaUrl('/performanceAnalysis/events'), {
    headers: {
      accept: MIMETYPE_CSV,
    },
  });
}

/* @see net/disy/cadenza/web/rest/SessionMetadataEndpointController.java */

//  undefined = net/disy/cadenza/workbook/performance/dto/WorkbookPerformanceEventDto.java */
export function setPerformanceMetadata(metadata: Record<string, string>) {
  return ajax<undefined>('/session/performanceMetadata', {
    type: 'PUT',
    data: JSON.stringify(metadata),
    contentType: MIMETYPE_JSON,
  }).catch((e) => {
    if (e.status === 404) {
      logger.error('Performance Logging Metadata Feature is not enabled.');
    }
  });
}

export function clearPerformanceMetadata() {
  return ajax('/session/performanceMetadata', {
    type: 'DELETE',
  }).catch((e) => {
    if (e.status === 404) {
      logger.error('Performance Logging Metadata Feature is not enabled.');
    }
  });
}
