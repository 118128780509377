import h from 'hyperscript';

import type { HyperScriptButtonOptions } from 'ui/button/button';
import { applyButtonStyle } from 'ui/button/button';
import type { Content } from 'ui/content';

import { assert } from 'cadenza/utils/custom-error';
import type { Icon } from 'cadenza/utils/icon/icon';

interface ButtonAnchorOptions extends HyperScriptButtonOptions {
  target?: string;
}

/**
 * Creates an anchor element that is styled like a button.
 *
 * @param content - The contents must contain some text.
 * @param url - The url for the anchor element
 * @param [options]
 * @return The anchor element
 */
export function createButtonAnchor(content: Content, url: string, options?: ButtonAnchorOptions) {
  const anchorElement = createButtonAnchorInternal(content, url, options);
  assert(!!anchorElement.textContent?.trim(), 'Label is mandatory');
  return anchorElement;
}

/**
 * Creates an anchor element that is styled like an icon button.
 *
 * @param icon - The icon (`.d-icon`)
 * @param title - The anchor element's title
 * @param url - The url for the anchor element
 * @param [options]
 * @return The anchor element
 */
export function createIconButtonAnchor(icon: Icon, title: string, url: string, options?: ButtonAnchorOptions) {
  assert(icon.classList.contains('d-icon'), 'Icon must be a .d-icon');
  const anchorElement = createButtonAnchorInternal(icon, url, { ...options, title });
  anchorElement.classList.add('button-icon');
  return anchorElement;
}

function createButtonAnchorInternal(
  content: Content,
  url: string,
  { target, variant, styleClass, size, ...props }: ButtonAnchorOptions = {},
): HTMLAnchorElement {
  const anchorElement = h<HTMLAnchorElement>('a.button', { href: url, ...props }, content);

  if (target) {
    anchorElement.target = target;
  }
  applyButtonStyle(anchorElement, { variant, styleClass, size });
  return anchorElement;
}
