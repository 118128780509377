import h from 'hyperscript';

import { createAnchorButton } from 'ui/button/button';
import { type Popup, type PopupContentFunction, setupPopupButton } from 'ui/popup/popup';

import { tearDown, type Teardown } from 'cadenza/utils/teardown';
import { on } from 'cadenza/utils/event-util';
import { assert } from 'cadenza/utils/custom-error';
import { escapeAndMarkHTML } from 'cadenza/utils/escape-and-mark';

import i18n from './truncated-p.properties';
import './truncated-p.css';

const COMPONENT_NAME = 'd-truncated-p';
const ATTR_FULL_TEXT = 'full-text';
const ATTR_TRUNCATED_TEXT = 'truncated-text';
const ATTR_DISPLAY_EXPANDED_CONTENT_IN_FLYOUT = 'display-expanded-content-in-flyout';

/**
 * A wrapper around the `<p>` element, that displays its text truncated and
 * can either be expanded to the full text or the full text can be shown in a flyout.
 *
 * There are two ways to use it:
 * 1. By default, the full text is truncated with ellipsis (…) after a certain number of lines
 *    (see `truncated-p.css`). When the text is truncated, a "More" button is shown to expand the
 *    full text.
 * 2. You might pass a shorter alternative text to show by default. In this case, a "More" / "Less"
 *    button toggles between the full text and the alternative text.
 *
 * Both, full and alternative text, might include limited formatting (see `mark()` function).
 *
 * _Note:_ The component can be initialized also via attributes.
 *
 * @property fullText - The text of the paragraph (linked to the `full-text` attribute)
 * @property expandedContent - Optional, a content producing function that will be used instead of the full text to render the content when the component is expanded
 * @property truncatedText - A shorter alternative text (linked to the `truncated-text` attribute)
 * @property shouldDisplayExpandedContentInFlyout - If set to true, the "More" button is always shown and clicking it will
 * display a flyout with the fullText
 */
export class TruncatedP extends HTMLElement {
  #tearDowns: Teardown[] = [];
  #expandedContent?: PopupContentFunction;
  #p: HTMLElement = h(`p.${COMPONENT_NAME}--paragraph-container.d-stack-v`);
  #paragraphEllipsis = h(`p.${COMPONENT_NAME}--paragraph-ellipsis`, { attrs: { 'aria-hidden': 'true' } }, '…');
  // Necessary to instantiate the button here since some property setters could be called before adding this element to the DOM
  // resulting in the #render method being called and the button not being defined (if we were to create the button in connectedCallback)
  #moreLessButton = createAnchorButton(i18n('more'));
  #resizeObserver = new ResizeObserver(() => this.#onResize());

  connectedCallback() {
    this.classList.add(COMPONENT_NAME, 'd-stack-v', 'space-1');
    this.setAttribute('aria-live', 'polite');
    this.#setupMoreLessButton();
    this.append(this.#p, this.#moreLessButton);
    this.#render();
  }

  disconnectedCallback() {
    this.#resizeObserver.disconnect();
    tearDown(this.#tearDowns);
  }

  #setupMoreLessButton() {
    if (this.shouldDisplayExpandedContentInFlyout) {
      this.#tearDowns = [
        setupPopupButton(this.#moreLessButton, (target, popup) => this.#getPopupContent(target, popup), {
          styleClass: `${COMPONENT_NAME}--flyout`,
          arrow: true,
          placement: 'bottom',
        }),
      ];
    } else {
      on(this.#moreLessButton, 'click', (event) => {
        event.stopPropagation();
        this.#collapsed = !this.#collapsed;
      });
    }
  }

  #render() {
    const collapsed = this.#collapsed;
    const truncatedText = this.truncatedText;
    const fullText = this.fullText;

    const pContainer = this.#p;
    pContainer.innerHTML = escapeAndMarkHTML(!(collapsed && truncatedText) ? fullText : truncatedText);

    /*
     * Firefox does not support CSS line-clamp on multiple paragraphs, so hide sub-sequent paragraphs.
     *
     * Since ellipsis is a purely visual single thing (all text is still read by screen readers,
     * see https://butterpep.com/line-clamp-overflow-ellipsis.html), we hide the paragraphs only
     * visually.
     */
    [...pContainer.children].forEach((p, i) => p.classList.toggle('visuallyhidden', i > 0 && collapsed));
    if (pContainer.children.length > 1) {
      pContainer.append(this.#paragraphEllipsis);
    }

    this.#moreLessButton.textContent = i18n(collapsed ? 'more' : 'less'); // reusing the buttons maintains the focus

    this.#onResize();

    // We need the observer only for the ellipsis use case.
    if (!collapsed || truncatedText) {
      this.#resizeObserver.unobserve(this);
    } else {
      this.#resizeObserver.observe(this);
    }
  }

  #onResize() {
    const ps = this.#p.children;
    const firstP = ps[0] as HTMLElement;
    // For whatever reason, the scrollHeight is 2px bigger even if there's no overflow.
    const firstPHasEllipsis = firstP.offsetHeight < firstP.scrollHeight - 2;
    this.#paragraphEllipsis.hidden = firstPHasEllipsis;
    this.#moreLessButton.hidden =
      !this.#expandedContent && // when expandedContent is defined always show the more button as the expanded content usually has more data than the full text
      ps.length === 1 &&
      !firstPHasEllipsis &&
      this.#collapsed &&
      !this.truncatedText;
  }

  set #collapsed(value: boolean) {
    if (this.shouldDisplayExpandedContentInFlyout) {
      throw new Error(
        'If the shouldDisplayExpandedContentInFlyout mode is on, there should never be calls to expand or collapse this element',
      );
    }
    // Not using the aria-expanded attribute, because that's for use with certain roles.
    this.classList.toggle('is-expanded', !value);
    this.#render();
  }

  get #collapsed(): boolean {
    return !this.classList.contains('is-expanded') || this.shouldDisplayExpandedContentInFlyout;
  }

  set fullText(value: string) {
    this.setAttribute(ATTR_FULL_TEXT, value);
    this.#render();
  }

  get fullText(): string {
    return this.getAttribute(ATTR_FULL_TEXT) ?? '';
  }

  set truncatedText(value: string | undefined) {
    if (value) {
      this.setAttribute(ATTR_TRUNCATED_TEXT, value);
    } else {
      this.removeAttribute(ATTR_TRUNCATED_TEXT);
    }
    this.#render();
  }

  get truncatedText(): string | undefined {
    return this.getAttribute(ATTR_TRUNCATED_TEXT) ?? undefined;
  }

  get shouldDisplayExpandedContentInFlyout() {
    return Boolean(this.getAttribute(ATTR_DISPLAY_EXPANDED_CONTENT_IN_FLYOUT));
  }

  set expandedContent(expandedContent: PopupContentFunction) {
    assert(
      this.shouldDisplayExpandedContentInFlyout,
      'No expanded content can be set when the shouldDisplayExpandedContentInFlyout is falsey',
    );
    this.#expandedContent = expandedContent;
  }

  #getPopupContent(target: Element | undefined, popup: Popup) {
    if (this.#expandedContent) {
      return this.#expandedContent(target, popup);
    }
    return this.fullText;
  }
}

customElements.define(COMPONENT_NAME, TruncatedP);
declare global {
  interface HTMLElementTagNameMap {
    [COMPONENT_NAME]: TruncatedP;
  }
}
