import h from 'hyperscript';

import 'ui/panel-section/panel-section.css';

// Must be the same as the "navigator breakpoint" set in CSS.
import { currentWindowMediaMdUp } from 'cadenza/utils/media';
import { on } from 'cadenza/utils/event-util';

import './sidebar.css';

const COMPONENT_NAME = 'd-sidebar';
const CLOSE_BUTTON_CLASS_SELECTOR = `.${COMPONENT_NAME}-close`;

class Sidebar extends HTMLElement {
  readonly #backdrop = h(`.${COMPONENT_NAME}--backdrop`);
  #previousOpenState = this.open;
  readonly #shouldOpenOnMobile;
  #autoOpen = false;
  #autoOpenMediaChangeCallback = () => this.adaptToCurrentMediaQueryState();

  constructor({ autoOpen = true, shouldOpenOnMobile = false } = {}) {
    super();
    this.#shouldOpenOnMobile = shouldOpenOnMobile;
    on(this, 'keydown', (event: KeyboardEvent) => {
      if (event.key === 'Escape' && this.#closeable) {
        this.close();
      }
    });
    on(this, 'click', () => this.close(), { delegate: CLOSE_BUTTON_CLASS_SELECTOR });

    this.autoOpen = autoOpen;
  }

  close() {
    this.open = false;
    this.dispatchEvent(new CustomEvent('close'));
  }

  connectedCallback() {
    this.setAttribute('role', 'complementary');
    this.classList.add(COMPONENT_NAME);
    this.#updateVisibility();
    this.parentElement!.insertBefore(this.#backdrop, this.nextSibling);
    this.adaptToCurrentMediaQueryState();
  }

  #updateVisibility(value = this.open) {
    this.style.display = value ? '' : 'none';
  }

  disconnectedCallback() {
    currentWindowMediaMdUp.removeEventListener('change', this.adaptToCurrentMediaQueryState);
    this.#backdrop.remove();
  }

  adaptToCurrentMediaQueryState() {
    // Hide the sidebar on small sized windows, but when increasing the window size do not show the
    // sidebar if it was not shown before.
    if (this.open && this.#autoHideWantsItHidden) {
      this.open = false;
      this.#previousOpenState = true;
    } else if (!this.open && !this.#autoHideWantsItHidden && this.#previousOpenState) {
      this.open = true;
    }
  }

  get #autoHideWantsItHidden() {
    return !currentWindowMediaMdUp.matches;
  }

  set open(value: boolean) {
    if (value !== this.open) {
      if (value || !(this.classList.contains('is-overlay') || this.classList.contains('is-animated'))) {
        this.#updateVisibility(value);
      }
      this.setAttribute('aria-hidden', String(!value));
      this.dispatchEvent(new CustomEvent('change:open', { detail: this.open }));
    }
  }

  /**
   * @return Whether the sidebar is open or not
   */
  get open() {
    return this.getAttribute('aria-hidden') !== 'true';
  }

  set autoOpen(value: boolean) {
    if (value) {
      this.open = this.open && (!this.#autoHideWantsItHidden || this.#openOnMobile);
      currentWindowMediaMdUp.addEventListener('change', this.#autoOpenMediaChangeCallback);
    } else {
      currentWindowMediaMdUp.removeEventListener('change', this.#autoOpenMediaChangeCallback);
    }
    this.#autoOpen = Boolean(value);
  }

  /**
   * @return Whether the sidebar is supposed to be open initially or not
   */
  get autoOpen() {
    return this.#autoOpen;
  }

  get #closeable() {
    return !!this.querySelector(CLOSE_BUTTON_CLASS_SELECTOR);
  }

  get #openOnMobile() {
    return this.#shouldOpenOnMobile || this.getAttribute('open-on-mobile') === 'true';
  }
}

customElements.define(COMPONENT_NAME, Sidebar);
