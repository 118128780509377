import h from 'hyperscript';
import what3wordsIcon from '@disy/cadenza-icons/what3words.svg';

import buttonStyles from 'ui/button/button.module.css';
import iconStyles from 'ui/icon/icon.module.css';
import { createClipboardButton } from 'ui/clipboard-button/clipboard-button';

import { icon } from 'cadenza/utils/icon/icon';

const COMPONENT_NAME = 'd-what3words-label';

/**
 * What3Words label containing icon, words and button to copy the words to clipboard
 */
class What3WordsLabel extends HTMLElement {
  override shadowRoot!: ShadowRoot;

  static readonly observedAttributes = ['words'] as const;

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.shadowRoot.adoptedStyleSheets = [buttonStyles, iconStyles];
  }

  connectedCallback() {
    this.classList.add('d-stack-h', 'space-1');
    this.shadowRoot.append(
      icon(what3wordsIcon, { size: 's' }),
      h('span', this.words),
      createClipboardButton(() => this.words, { styleClass: 'button-borderless' }),
    );
  }

  attributeChangedCallback(name: string) {
    if (name === 'words') {
      this.#setWordsContent(this.words);
    }
  }

  get words() {
    return this.getAttribute('words') ?? '';
  }

  set words(words: string) {
    this.setAttribute('words', words);
  }

  #setWordsContent(words: string) {
    const span = this.shadowRoot.querySelector('span');
    if (span) {
      span.textContent = words ?? '';
    }
  }
}

customElements.define(COMPONENT_NAME, What3WordsLabel);
declare global {
  interface HTMLElementTagNameMap {
    [COMPONENT_NAME]: What3WordsLabel;
  }
}
