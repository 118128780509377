import { cadenzaSessionStorage, cadenzaLocalStorage } from 'cadenza/utils/storage/storage';

const ITEM_SESSIONID = 'clientSessionId';

/**
 * Method to call when Cadenza Web base is loaded: Clears the sessionStorage and localStorage.
 */
export function initializeCadenzaStorage() {
  const clientSessionId = window.Disy.clientSessionId;

  if (cadenzaLocalStorage.getItem(ITEM_SESSIONID) !== clientSessionId) {
    cadenzaLocalStorage.clear();
    cadenzaLocalStorage.setItem(ITEM_SESSIONID, clientSessionId);
  }

  if (cadenzaSessionStorage.getItem(ITEM_SESSIONID) !== clientSessionId) {
    cadenzaSessionStorage.clear();
    cadenzaSessionStorage.setItem(ITEM_SESSIONID, clientSessionId);
  }
}
