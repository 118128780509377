import h from 'hyperscript';
import submenuArrowIcon from '@disy/cadenza-icons/submenu-arrow.svg';

import { createButton } from 'ui/button/button';
import type { LabelWithIcon } from 'ui/label-with-icon/label-with-icon';
import type { OpenPopupOptions, Popup, PopupTarget } from 'ui/popup/popup';
import { closeAllPopups, closePopup, getPopupContent, isButton, openPopup } from 'ui/popup/popup';
import 'ui/progress-spinner/progress-spinner';
import { createButtonAnchor } from 'ui/button/anchor';

import { assert } from 'cadenza/utils/custom-error';
import { focusFirst, focusLast, focusNext, focusPrevious, on, unByKey } from 'cadenza/utils/event-util';
import type { Icon } from 'cadenza/utils/icon/icon';
import { applyIconVariant, isIcon, emptyIcon, icon } from 'cadenza/utils/icon/icon';
import { PLACEMENT } from 'cadenza/utils/position';
import { uniqueId } from 'cadenza/utils/unique-id';
import { addStyleClass } from 'cadenza/utils/add-style-class';

import './menu.css';

// re-export
export { PLACEMENT };

const COMPONENT_NAME = 'd-menu';

const MENU_SELECTOR = '[role=menu]';
const MENU_ITEM_SELECTOR = '[role^=menuitem]';
const MENU_BUTTON_SELECTOR = '[aria-haspopup=menu]';

/** A function returning the menu content */
export type MenuContent = () => MenuContentElement[] | Promise<MenuContentElement[]>;
/** Undefined content is filtered out. */
export type MenuContentElement = MenuItem | MenuItemGroup | MenuItemSeparator | Node | string | undefined;

/**
 * The description of a menu item
 *
 * Either "execute", "url" or "subMenu" are mandatory.
 */
export interface MenuItem {
  /** The item label */
  label: string | LabelWithIcon;
  /** An icon for the menu item */
  icon?: Icon;
  /** class of the item */
  styleClass?: string | string[];
  /** A tooltip for the menu item */
  tooltip?: string;
  /** An action listener for the menu item */
  execute?: (event: MouseEvent) => void;
  /** The href of a link menu item */
  url?: string;
  /** The target of a link menu item */
  target?: string;
  /** The content of a sub-menu */
  subMenu?: MenuContent | MenuContentElement[];
  /** Whether the item is disabled (not for link menu items) */
  disabled?: boolean;
  /** Whether the item is checked when used in a {@link MenuItemGroup} */
  checked?: boolean;
  /** ID for E2E tests */
  testid?: string;
  /** Formatted hotkey (see `getFormattedHotkey()`) that triggers the functionality of the menu item */
  hotkey?: string;
}

/** The description of a menu item group */
export interface MenuItemGroup {
  /** The group menu items */
  group: (Exclude<MenuContentElement, MenuItemGroup> | undefined)[];
  /** The type of the group (affects the menu item's role) */
  groupType?: 'radio';
  /** A group label */
  label?: string;
  /** If a group should not be followed by a separator, set this to false. */
  separator?: boolean;
}

export type MenuItemSeparator = '---';
export const MENU_ITEM_SEPARATOR: MenuItemSeparator = '---';

/** Options for opening a menu */
interface MenuOptions extends OpenPopupOptions {
  /** A CSS class name to be added to the menu */
  styleClass?: string | string[];
  /** A selector for descendants to set up as menu buttons. They must specify `aria-haspopup=menu`. */
  delegate?: string;
}

/**
 * Sets a button up to open a menu when activated.
 *
 * @see {@link https://www.w3.org/TR/wai-aria-practices/#menubutton}
 * @param target - The button to set up (either a `<button>` or an element with the "button" role)
 * @param content - The menu content
 * @param options - Menu options that extend the popup options
 * @return A function to remove the listeners from the target
 */
export function setupMenuButton(target: Element, content: MenuContent, options: MenuOptions = {}): () => void {
  const delegate = options.delegate;

  if (!delegate) {
    assert(isButton(target), 'target must be a button');
    target.setAttribute('aria-haspopup', 'menu');
  }

  const eventKeys = [
    on(
      target,
      'pointerdown',
      (event) => {
        // Prevent already open menu from closing while mouse button is pressed (see addGlobalListener() in popup.js)
        // and then reopening again (via the click event handler below); instead it should do nothing on pointerdown
        // and close on click.
        event.stopPropagation();
      },
      { delegate },
    ),

    on(
      target,
      'click',
      (event) => {
        const button = event.delegateTarget || target;
        assert(button.matches(MENU_BUTTON_SELECTOR), 'target must indicate a menu popup to screen readers');

        if (button.getAttribute('aria-expanded') !== 'true') {
          openMenu(button, content, options);
        } else if (!button.closest(MENU_SELECTOR)) {
          closeMenu();
        }
      },
      { delegate },
    ),
  ];

  return () => {
    closeMenu();
    unByKey(eventKeys);
  };
}

export function openMenu(target: Element, content: MenuContent, options: MenuOptions = {}) {
  return openMenuInternal(target, content, { placement: PLACEMENT.BOTTOM_START, ...options });
}

/*
 * Note: Context menus are work in progress: Semantics are not correct and some things do work.
 *
 * A context menu is a menu with an arrow pointing to some content, providing contextual actions.
 */
export function setupContextMenu(
  target: Element,
  content: MenuContent,
  options: MenuOptions & {
    initiallyOpen?: boolean;
  } = {},
) {
  const { delegate, initiallyOpen } = options;

  if (!delegate) {
    target.setAttribute('aria-haspopup', 'menu');
  }

  const eventKeys = [
    on(target, 'pointerdown', (event) => event.stopPropagation(), { delegate }),
    on(
      target,
      'click',
      (event) => {
        const button = event.delegateTarget || target;
        assert(button.matches(MENU_BUTTON_SELECTOR), 'target must indicate a menu popup to screen readers');
        openContextMenu(target, content, options);
      },
      { delegate },
    ),
  ];

  if (initiallyOpen) {
    openContextMenu(target, content, options);
  }

  return () => {
    closeMenu();
    unByKey(eventKeys);
  };
}

export function openContextMenu(target: PopupTarget, content: MenuContent, options: MenuOptions) {
  return openMenuInternal(target, content, { arrow: true, ...options });
}

function openMenuInternal(target: PopupTarget, content: MenuContent, menuOptions: MenuOptions) {
  const menu = createMenu({ styleClass: menuOptions.styleClass });
  menu.append(...getMenuContent(content, menu, target, menuOptions.autoFocus));
  openPopup(target, menu, menuOptions);
  return menu;
}

function createMenu({ styleClass }: MenuOptions) {
  const menu = h(`.${COMPONENT_NAME}#${uniqueId()}`, {
    attrs: { role: 'menu' },
    onclick: (event: Event) => {
      const button = (event.target as Element).closest(MENU_ITEM_SELECTOR);
      if (button && !button.matches(MENU_BUTTON_SELECTOR)) {
        closeMenu();
      }
    },
    onkeydown: (event: KeyboardEvent) => onMenuKeydown(event),
  });
  addStyleClass(menu, styleClass);
  return menu as Popup;
}

function onMenuKeydown(event: KeyboardEvent) {
  const key = event.key;
  if (key === 'Escape') {
    // prevent closing ancestor dialogs (both are needed)
    event.stopPropagation();
    event.preventDefault();
    closePopup();
    return;
  }

  // Do not use menu keyboard behavior when we're not on a menu item
  // to not interfere with the keyboard behavior of custom menu content.
  const menuItem = (event.target as Element).closest(MENU_ITEM_SELECTOR);
  if (!menuItem) {
    return;
  }

  if (key === 'Tab') {
    closeMenu();
  } else if (['Home', 'End', 'ArrowUp', 'ArrowDown'].includes(key)) {
    event.stopPropagation();
    event.preventDefault(); // prevent scrolling the page (which closes the menu)
    const menu = event.currentTarget as HTMLElement;
    document.body.classList.add('is-keynav');
    if (key === 'Home') {
      focusFirst(menu);
    } else if (key === 'End') {
      focusLast(menu);
    } else if (key === 'ArrowUp') {
      focusPrevious(menu);
    } else if (key === 'ArrowDown') {
      focusNext(menu);
    }
  } else if (key === 'ArrowLeft') {
    event.stopPropagation();
    event.preventDefault(); // prevent scrolling the page
    closePopup();
  } else if (key === 'ArrowRight') {
    event.stopPropagation();
    event.preventDefault(); // prevent scrolling the page
    if (menuItem && menuItem.matches(MENU_BUTTON_SELECTOR)) {
      (menuItem as HTMLElement).click();
    } else {
      closeMenu();
    }
  }
}

export function getMenuContent(content: MenuContent, menu: Popup, target: PopupTarget, autoFocus: boolean = true) {
  let hasIcon: boolean | undefined;
  return getPopupContent(content, target instanceof Element ? target : undefined, menu, autoFocus, (item, i, items) => {
    // If the sub-menu is defined as an array, we can filter it out if it's empty.
    if (isEmptyMenuItem(item)) {
      return [];
    }
    const previousItem = items[i - 1];
    const nextItem = items[i + 1];
    if (isMenuItemGroup(item)) {
      const group = [createItemGroup(item)];
      // add a separator before and after the group if necessary
      if (item.separator !== false) {
        if (needsSeparator(previousItem)) {
          group.unshift(createMenuItemSeparator());
        }
        if (needsSeparator(nextItem) && !isMenuItemGroup(nextItem)) {
          group.push(createMenuItemSeparator());
        }
      }
      hasIcon = undefined;
      return group;
    }
    if (item === MENU_ITEM_SEPARATOR) {
      hasIcon = undefined;
      return needsSeparator(previousItem) && nextItem ? createMenuItemSeparator() : [];
    }
    if (!isMenuItem(item)) {
      return item;
    }
    if (hasIcon === undefined) {
      hasIcon = getItemsTillNextSeparator(items, i).some(
        (nextItemTillSeparator) => isMenuItem(nextItemTillSeparator) && nextItemTillSeparator.icon !== undefined,
      );
    }
    return createMenuItem(item, hasIcon);
  });
}

function isMenuItem(item: MenuContentElement): item is MenuItem {
  return !(item instanceof HTMLElement) && (item as MenuItem).label !== undefined;
}

function isMenuItemGroup(item: MenuContentElement): item is MenuItemGroup {
  return (item as MenuItemGroup).group !== undefined;
}

function isEmptyMenuItem(item: MenuContentElement) {
  return isEmptyMenuItemGroup(item) || isEmptySubMenuItem(item);
}
function isEmptySubMenuItem(item: MenuContentElement) {
  return isMenuItem(item) && Array.isArray(item.subMenu) && !item.subMenu.some(Boolean);
}
function isEmptyMenuItemGroup(item: MenuContentElement) {
  return isMenuItemGroup(item) && !item.group.some(Boolean);
}

function needsSeparator(item: MenuContentElement) {
  // Note: IntelliJ is wrong, the separator === false part cannot be simplified, because separator can be undefined.
  return item && !isEmptyMenuItem(item) && item !== MENU_ITEM_SEPARATOR && (item as MenuItemGroup).separator !== false;
}

function getItemsTillNextSeparator(content: MenuContentElement[], fromIndex = 0) {
  const nextItems = content.slice(fromIndex);
  const nextSeparatorIndex = nextItems.findIndex((item) => item === MENU_ITEM_SEPARATOR || isMenuItemGroup(item));
  return nextSeparatorIndex === -1 ? nextItems : nextItems.slice(0, nextSeparatorIndex);
}

function createItemGroup({ label, group, groupType }: MenuItemGroup) {
  if (groupType === 'radio') {
    assert(
      group.filter((item) => (item as MenuItem)?.checked).length <= 1,
      'Maximum one item in a radio group may be checked.',
    );
  }

  const groupEl = h('div', { attrs: { role: 'group' } });
  if (label) {
    const labelEl = h('label', { id: uniqueId() }, label);
    groupEl.setAttribute('aria-labelledby', labelEl.id);
    groupEl.append(labelEl);
  }
  const hasIcon = group.some((item) => (item as MenuItem)?.icon);
  const groupedItems = group.flatMap((item) => {
    if (item == null) {
      return [];
    }
    if (!isMenuItem(item)) {
      return [item];
    }
    const itemEl = createMenuItem(item, hasIcon);
    if (groupType === 'radio') {
      itemEl.setAttribute('role', 'menuitemradio');
    }
    itemEl.setAttribute('aria-checked', Boolean(item.checked).toString());
    return [itemEl];
  });
  groupEl.append(...groupedItems);
  return groupEl;
}

function createMenuItemSeparator() {
  return h('hr');
}

function createMenuItem(
  { label, icon, tooltip, execute, url, target, subMenu, disabled, testid, styleClass, hotkey }: MenuItem,
  hasIcon = false,
) {
  assert(label, 'The menu item label is mandatory.');
  assert(execute || url || subMenu, 'Either "execute", "url" or "subMenu" are mandatory.');

  const wrappedLabel = h('.button--label.ellipsis', label);

  let item!: HTMLElement;
  if (execute) {
    item = createButton(wrappedLabel, {
      onclick: execute,
      disabled,
    });
  } else if (url) {
    item = createButtonAnchor(wrappedLabel, url, { target });
  } else if (subMenu) {
    item = createButton([wrappedLabel, createSubmenuIcon()], {
      disabled,
      onmouseenter() {
        item.click(); // open sub-menu when entering
      },
      onmouseleave(event: MouseEvent) {
        const menu = item.closest(MENU_SELECTOR);
        const relatedMenu = (event.relatedTarget as Element).closest(MENU_SELECTOR);
        if (menu === relatedMenu) {
          // close sub-menu when leaving inside the same menu
          //
          // Pass the target to closePopup() to ensure that the current popup was opened from this item.
          // Otherwise, an unrelated popup would be closed. This could happen if ...
          // - The item is disabled and hence no sub-menu was opened from it.
          // - The sub-menu was already closed, e.g. because of a scroll event in its parent menu.
          closePopup(item);
        }
      },
    });
    setupMenuButton(item, Array.isArray(subMenu) ? () => subMenu : subMenu, {
      placement: PLACEMENT.RIGHT_START,
      appendTo: () => (item.closest(MENU_SELECTOR) as Node).parentElement as HTMLElement, // append to where the parent menu is appended
    });
  }

  if (icon) {
    assert(
      isIcon(icon),
      'The value of the "icon" option must be an element with the "d-icon" or "d-icon-stack" CSS class',
    );
    applyIconVariant(icon, 'normal');
    item.prepend(icon);
  } else if (hasIcon) {
    // if at least one menu item (in an item group) has an icon, hasIcon is true for all of them, so all menu items are aligned within a group
    item.prepend(emptyIcon());
  }

  if (tooltip) {
    item.title = tooltip;
  }

  if (testid) {
    item.dataset.testid = testid;
  }

  if (hotkey) {
    // item.append(h('.d-menuitem-hotkey', hotkey));
  }

  item.setAttribute('role', 'menuitem');

  /*
   * When we open a popup, we move the focus to the first tabbable inside of it. When it's closed,
   * we move the focus to the previously active element.
   *
   * When a sub-menu is closed, the item that opened it should be focused, even if the user opened
   * the sub-menu by hovering it. That's also what native menus do: If you hover a menu item and
   * then continue navigating with the arrow keys, the navigation starts with the last hovered item.
   */
  on(item, 'mouseenter', (event) => (event.target as HTMLElement).focus());
  addStyleClass(item, styleClass);
  return item;
}

function createSubmenuIcon() {
  return icon(submenuArrowIcon, { styleClass: 'd-submenu-arrow' });
}

export function closeMenu() {
  closeAllPopups((popup: Element) => popup.matches(MENU_SELECTOR));
}
