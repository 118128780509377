/*
 * The execution of this "base" bundle is deferred, so that it's not render-blocking.
 * That means that we cannot rely on a certain execution order relative to other bundles and scripts.
 *
 * In general, we want to know the dependencies of our pages, so their dependencies should be defined
 * explicitly in the respective page's bundle.
 */

// Note: CSS is split out of the JS bundles and won't be deferred.
// eslint-disable-next-line @typescript-eslint/no-restricted-imports -- We do not have a Webpack resolve alias for that.
import '../css/base.css';

import 'ui/alert/alert';
import 'ui/alerts/alerts';
import 'ui/progress-spinner/progress-spinner';
import 'ui/sidebar/sidebar';
import 'ui/progress-spinner-overlay/progress-spinner-overlay';
import { setupEllipsis } from 'ui/ellipsis/ellipsis';

import 'cadenza/header/subnav';
import 'cadenza/header/topnav';
import { logger } from 'cadenza/utils/logging';
import { setupKeynav } from 'cadenza/utils/setup-keynav';
import { setupSessionPoller } from 'cadenza/session-polling/session-poller';
import { postEvent } from 'cadenza/integration/post-message';
import { clearPerformanceMetadata, setPerformanceMetadata } from 'cadenza/api-client/performance-analysis-api';
import { initializeCadenzaStorage } from 'cadenza/utils/storage/storage-util';
import { cadenzaLocalStorage } from 'cadenza/utils/storage/storage';
import 'cadenza/header/purpose-of-request-badge/purpose-of-request-badge';
import 'cadenza/ui/what3words-label/what3words-label';
import 'cadenza/hotkeys/hotkey-definitions'; // Create hotkeys
import { initializeConfiguration } from 'cadenza/config/configuration';

import i18n from './base.properties';

initializeCadenzaStorage();
setupSessionPoller(window.Disy.cadenzaSessionPollingInterval);
setupKeynav();
setupEllipsis();
initializeConfiguration();

Object.assign(window.Disy, {
  postEvent,

  // Used in internal load tests
  setPerformanceMetadata,
  clearPerformanceMetadata
});

const userSessionInfo = window.Disy.userSessionInfo;
if (userSessionInfo) {
  const clientSessionId = window.Disy.clientSessionId;
  const { previousLoginFailureCount } = userSessionInfo;
  if (previousLoginFailureCount > 0
    && cadenzaLocalStorage.getItem('failedLoginCountWarning') !== clientSessionId) {
    // eslint-disable-next-line no-alert -- For performance reasons, the errorAlert dialog has been omitted.
    alert(i18n('user.failedLoginCountWarning.message', { count: previousLoginFailureCount }));
    cadenzaLocalStorage.setItem('failedLoginCountWarning', clientSessionId);
  }

  const { primaryPasswordState, daysBeforePasswordExpiration } = userSessionInfo;
  if (primaryPasswordState === 'WARN_FOR_NEAR_PASSWORD_EXPIRATION'
    && cadenzaLocalStorage.getItem('nearPasswordExpirationWarning') !== clientSessionId) {
    // eslint-disable-next-line no-alert -- For performance reasons, the errorAlert dialog has been omitted.
    alert(i18n('user.warnNearPasswordExpiration.message', { count: daysBeforePasswordExpiration }));
    cadenzaLocalStorage.setItem('nearPasswordExpirationWarning', clientSessionId);
  }
}

if (window.Disy.debug) {
  document.addEventListener('focusout', (event) => {
    // "focusout" is dispatched before the element is removed.
    // So we need to defer the contains() check.
    requestAnimationFrame(() => {
      if (document.activeElement === document.body && !document.body.contains(event.target)) {
        logger.log('Please fix the focus management of your component: '
          + 'The focus was dropped, because the active element (see below) was removed from the DOM. '
          + 'As a result, the document.body is focused now, which breaks the UX for keyboard users',
        event.target);
      }
    });
  });
}
