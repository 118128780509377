import { on } from 'cadenza/utils/event-util';

export function setupEllipsis() {
  return on(document.documentElement, 'mouseover', (event) => ellipsis(event.delegateTarget), {
    delegate: '.ellipsis, .multiline-ellipsis',
  });
}

/**
 * Put the textContent of an element into its title if necessary.
 *
 * @param element - The element to set the title on
 */
function ellipsis(element?: Element) {
  if (!(element instanceof HTMLElement)) {
    return;
  }
  const hasTitle = element.hasAttribute('title');
  const needsTitle =
    element.matches('.multiline-ellipsis') ?
      element.offsetHeight < element.scrollHeight
    : element.offsetWidth < element.scrollWidth;

  if (!hasTitle && needsTitle && element.textContent) {
    element.title = element.textContent.replaceAll(/(\s{2,}|\n)/g, ' ').trim();
  } else if (hasTitle && !needsTitle) {
    element.removeAttribute('title');
  }
}
