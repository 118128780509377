import h from 'hyperscript';
import editIcon from '@disy/cadenza-icons/edit.svg';

import { createButton } from 'ui/button/button';
import { setupMenuButton } from 'ui/menu/menu';

import { logger } from 'cadenza/utils/logging';
import { icon } from 'cadenza/utils/icon/icon';
import type { PurposeOfRequestFormConfiguration } from 'cadenza/api-client/purpose-of-request-api';
import { getPurposeOfRequestFormConfiguration } from 'cadenza/api-client/purpose-of-request-api';

import './purpose-of-request-badge.css';
import i18n from './purpose-of-request-badge.properties';

const COMPONENT_NAME = 'd-purpose-of-request-badge';

class PurposeOfRequestBadge extends HTMLElement {
  // The fields are assigned in connectedCallback()
  #button!: HTMLButtonElement;
  #reason1!: string;
  #reason2!: string;

  connectedCallback() {
    this.classList.add(COMPONENT_NAME);
    this.#button = this.querySelector<HTMLButtonElement>('.button')!; // Button is in the template.
    this.#reason1 = this.querySelector('.purpose-of-request-reason-1')!.textContent!; // Element is in the template.
    this.#reason2 = this.querySelector('.purpose-of-request-reason-2')!.textContent!; // Element is in the template.
    setupMenuButton(this.#button, () =>
      getPurposeOfRequestFormConfiguration().then((config) => this.#createMenuContent(config)),
    );
  }

  #createMenuContent(config: PurposeOfRequestFormConfiguration) {
    const [field1Config, field2Config] = config.fields;
    const contents = [
      h('h2', i18n('flyout.title')),
      h('form-group', [h('label', field1Config.printName), this.#reason1 || '–']),
    ];
    if (field2Config) {
      contents.push(h('form-group', [h('label', field2Config.printName), this.#reason2 || '–']));
    }
    contents.push(
      createButton([icon(editIcon), i18n('editButton.title')], {
        onclick: () =>
          showPurposeOfRequestDialog(config, [this.#reason1, this.#reason2])
            .then((result) => {
              if (result) {
                // result can be unidentified when dialog is cancelled or closed without submit
                this.#purposeOfRequestChange(result.values);
              }
            })
            .catch((error) => logger.error(error)),
      }),
    );
    return [h(`.${COMPONENT_NAME}--flyout.d-stack-v.space-2`, contents)];
  }

  #purposeOfRequestChange(values: string[]) {
    values.forEach((value, index) => {
      if (index === 0) {
        this.#reason1 = value;
      } else if (index === 1) {
        this.#reason2 = value;
      }
      this.querySelector<HTMLSpanElement>(`.purpose-of-request-reason-${index + 1}`)!.textContent = value;
    });
  }
}

customElements.define(COMPONENT_NAME, PurposeOfRequestBadge);

function showPurposeOfRequestDialog(config: PurposeOfRequestFormConfiguration, values: string[]) {
  return import('cadenza/purpose-of-request/purpose-of-request-dialog').then(({ PurposeOfRequestDialog }) =>
    new PurposeOfRequestDialog(config, values).connectAndShow(),
  );
}
