import { ajax } from 'cadenza/api-client/api';
import type { Configuration, ConfigurationName } from 'cadenza/config/configuration';

/**
 * Fetch configuration from the backend.
 * This is intended to be used in conjunction with fetchConfigurations().
 * The endpoints providing configuration should comply to the following conventions:
 * - Path starting with /config/, followed by a name
 * - Return a JSON object (not an array or single value)
 * @param configName
 */
export function getConfiguration<T extends ConfigurationName>(configName: T) {
  return ajax<Configuration[T]>(`/config/${configName}`, { dataType: 'json' });
}
