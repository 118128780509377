import $ from 'jquery';

import { cadenzaUrl } from 'cadenza/utils/cadenza-url/cadenza-url';
import { cadenzaTopWindow } from 'cadenza/utils/iframe';
import { on } from 'cadenza/utils/event-util';
import { PARAMETER_EMBEDDING_TARGET_NAME } from 'cadenza/api-client/workbook-embedding-targets-api';

const CADENZA_SESSION_TIMEOUT_EVENT_NAME = 'cadenza-session-timeout';
const SESSION_POLLING_URL = '/session-polling';
const KEEP_ALIVE_PARAM = 'keepAlive';
const CADENZA_LOGIN_LOGOUT_PAGE = '/login';
const INVALID_SESSION_REDIRECT_URL = `${CADENZA_LOGIN_LOGOUT_PAGE}?loginStatus=invalidSession`;
const INVALID_SESSION_AFTER_EMBEDDING_REDIRECT_URL = '/embedding-standby';

export function setupSessionPoller(cadenzaSessionPollingInterval) {
  // Poll the session only in the Cadenza top window and (obviously) only
  // when there actually is a session (e.g. not on the login page or the embedding-standby page).
  if (cadenzaSessionPollingInterval && window === cadenzaTopWindow && window.Disy.clientSessionId !== '') {
    // The base bundle is loaded in the header of the page, that's why we need to wait for the loading of the page being finished.
    // We need the loading of the page being finished to check whether or not we're on the login/logout page because on this
    // page we don't want to setup the Session Poller.
    $(document).ready(function () {
      // Add listener on window to handle session timeouts occuring in the ajax-function in the future
      on(window, CADENZA_SESSION_TIMEOUT_EVENT_NAME, handleCadenzaSessionTimeOut);
      setInterval(
        () => $.ajax(cadenzaUrl(SESSION_POLLING_URL)).fail(handleCadenzaSessionTimeOut),
        cadenzaSessionPollingInterval,
      );
    });
  }
}

function handleCadenzaSessionTimeOut() {
  const urlSearchParams = new URLSearchParams(location.search);
  if (urlSearchParams.has(PARAMETER_EMBEDDING_TARGET_NAME)) {
    location.href = cadenzaUrl(INVALID_SESSION_AFTER_EMBEDDING_REDIRECT_URL);
  } else {
    location.href = cadenzaUrl(INVALID_SESSION_REDIRECT_URL);
  }
}

/**
 * Sends an HTTP request to the backend keeping the session alive, alive in the sense of "life-sustaining measures" and
 * not in the sense of "fountain of youth", i.e. if the maximum session timeout is reached the session is terminated anyways.
 *
 * Needed for scenarios where the user can do things on the UI without causing requests to the backend.
 * If you use this, always with ._throttle/._debounce/SetInterval... please always use
 * the constant window.Disy.cadenzaSessionPollingInterval which gets its value from
 * TemplateContextProvider.SESSION_POLLING_INTERVAL_IN_SECONDS - In the explanation of this field you will find
 * the reason why this is a more sensible value than hardcode your own time.
 */
export function keepSessionAlive() {
  $.ajax(cadenzaUrl(`${SESSION_POLLING_URL}?${KEEP_ALIVE_PARAM}`));
}
