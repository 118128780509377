import h from 'hyperscript';

import { BUTTON_ICON_STYLE_CLASS, createButton } from 'ui/button/button';

import { timeout } from 'cadenza/utils/timeout';
import { theme } from 'cadenza/theming-api';

import './subnav.css';

class Subnav extends HTMLElement {
  constructor() {
    super();
    this._ready = timeout(); // when light DOM is ready
  }

  connectedCallback() {
    this.classList.add('is-resizable');
    this.classList.toggle('dark-bg', theme('view-header-invert'));

    this._ready.then(() => {
      if (this._centerControls) {
        this._centerControls.forEach((control) => this.append(control));
      }

      this.append((this._spacer = h('.d-spacer')));
    });
  }

  addAction(action) {
    const button = createActionButton(action.icon, action.label, action.id, action.styleClass);
    button.hidden = true;
    this.addButton(button);
    return action.isSupported().then((supported) => {
      button.onclick = () => action.execute();
      button.hidden = !supported;
      return button;
    });
  }

  addButton(button, { atFirstPosition = false } = {}) {
    this._ready.then(() => {
      if (atFirstPosition) {
        this.insertBefore(button, this.firstChild);
      } else {
        this.insertBefore(button, this._spacer.nextSibling);
      }
      this.lastToolbarWidth = this.getBoundingClientRect().width;
    });
  }

  removeElement(element) {
    if (!element) {
      return;
    }
    this.removeChild(element);
    this.lastToolbarWidth = this.getBoundingClientRect().width;
  }

  setCenterControls(controls) {
    if (this._centerControls) {
      this._centerControls.forEach((control) => control.remove());
      this.lastToolbarWidth = this.getBoundingClientRect().width;
    }
    if (controls) {
      controls.forEach((control) => this.insertBefore(control, this._spacer));
      this.lastToolbarWidth = this.getBoundingClientRect().width;
    }
    this._centerControls = controls;
  }
}

function createActionButton(buttonIcon, title, testid, styleClass) {
  const button = createButton([buttonIcon, h('.visuallyhidden', title)], {
    title,
    styleClass: [styleClass, BUTTON_ICON_STYLE_CLASS],
    variant: 'borderless',
  });
  if (testid) {
    button.dataset.testid = testid;
  }
  return button;
}

customElements.define('d-subnav', Subnav);
