import { logger } from 'cadenza/utils/logging';
import { settingsJsonParser } from 'cadenza/utils/settings-json-parser';

import './data.css';

export class Data<T> extends HTMLElement {
  connectedCallback() {
    this.hidden = true;
  }

  get value() {
    try {
      return settingsJsonParser<T>(this.textContent ?? '{}');
    } catch (error) {
      logger.debug('Error occurred while trying to parse the following textContent', this.textContent);
      throw error;
    }
  }
}

customElements.define('d-data', Data);
