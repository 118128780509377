import h from 'hyperscript';

import type { Alert, AlertType } from 'ui/alert/alert';

import './alerts.css';

const ATTR_COMPACT_STYLE = 'compact-message-style';

const COMPONENT_NAME = 'd-alerts';

export interface AlertMessage {
  type: AlertType;
  message?: string;
  title?: string;
}

/**
 * Component that allows programmatic control of a group of d-alert components.
 * Its API accepts alert-like objects that should have the following fields, matching the properties of d-alert:
 * type - a String, see the accepted types for d-alert
 * title - String, the title of the alert
 * message - String, the text message of the alert
 *
 * The component can contain d-alert elements. This is useful for server-side rendering.
 * Then JS can take over and replace these pre-rendered alerts or add new ones.
 */
export class Alerts extends HTMLElement {
  connectedCallback() {
    this.classList.add(COMPONENT_NAME);
  }

  /**
   * The currently displayed alerts
   *
   * @return Array of alert objects
   */
  get alerts() {
    return ([...this.children] as Alert[]).map(({ type, title, message }) => ({ type, title, message }));
  }

  /**
   * Sets the displayed alerts, replacing the current ones.
   *
   * @param alerts - An array of alert objects
   */
  set alerts(alerts: AlertMessage[]) {
    this.innerHTML = '';
    this.append(...alerts.map((alert) => this._buildAlertElement(alert)));
  }

  /**
   * If set to true, the alerts use the special compact styling.
   *
   * @param value - boolean value
   */
  set compactMessages(value) {
    this.toggleAttribute(ATTR_COMPACT_STYLE, value);
  }

  get compactMessages() {
    return this.hasAttribute(ATTR_COMPACT_STYLE);
  }

  _buildAlertElement(message: AlertMessage) {
    const alert = h(`d-alert.d-alert-${message.type}`, {
      title: message.title,
      message: message.message,
    });

    if (this.compactMessages) {
      alert.classList.add('d-alert-small');
    }

    return alert;
  }
}

customElements.define(COMPONENT_NAME, Alerts);
declare global {
  interface HTMLElementTagNameMap {
    [COMPONENT_NAME]: Alerts;
  }
}
