import $ from 'jquery';

export const KEYNAV_CLASS = 'is-keynav';

export function setupKeynav() {
  $(function doSetupKeynav() {
    document.body.addEventListener('keyup', (event) => {
      if (event.key === 'Tab') {
        document.body.classList.add(KEYNAV_CLASS);
      }
    });
    document.body.addEventListener('pointerdown', (event) => {
      document.body.classList.remove(KEYNAV_CLASS);
    });
  });
}
