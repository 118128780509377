import type { Settings } from 'cadenza/workbook/workbook-settings-util';

export function settingsJsonParser<T = unknown>(jsonText: string) {
  return JSON.parse(jsonText, (key, value) => {
    if (key === 'settings' || key === 'mapViewSettings') {
      parseSettings(value);
    } else if (isWorkbookSettings(value)) {
      parseSettings(value.value);
    }
    return value;
  }) as T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isWorkbookSettings(value: any) {
  return value && value.op === 'replace' && value.path === '/settings';
}

function parseSettings(value: Settings) {
  Object.entries(value).forEach(([settingsKey, settingsValue]) => {
    try {
      value[settingsKey] = JSON.parse(settingsValue as string);
    } catch {
      // This must be a string, because cannot be JSON parsed.
      value[settingsKey] = settingsValue;
    }
  });
}
