import copyIcon from '@disy/cadenza-icons/copy-to-clipboard.svg';

import { createButton, createIconButton } from 'ui/button/button';
import type { HyperScriptButtonOptions } from 'ui/button/button';
import { showToastMessage } from 'ui/toast-message/toast-message';

import { icon } from 'cadenza/utils/icon/icon';
import { copyToClipboard } from 'cadenza/utils/clipboard';

import i18n from './clipboard-button.properties';

/**
 * Creates a button to copy the given text to the clipboard.
 *
 * @param text - The text to copy or a supplier therefore
 * @param [options]
 * @param [options.toast] - A message to display when copying was successful
 * @param [options.styleClass] - A style class that is applied to the button
 */
export function createClipboardButton(
  text: string | (() => string),
  {
    toast,
    styleClass,
    variant,
    iconOnly = true,
  }: { toast?: string; iconOnly?: boolean } & Pick<HyperScriptButtonOptions, 'styleClass' | 'variant'> = {},
) {
  const options: HyperScriptButtonOptions = {
    variant,
    size: 's',
    onclick: () => copyToClipboardAndShowToastMessage(text, { toast }),
    styleClass,
  };
  return iconOnly ?
      createIconButton(icon(copyIcon), i18n('title'), options)
    : createButton([icon(copyIcon), i18n('title')], options);
}

/**
 * Copies the given text to the clipboard.
 *
 * @param text - The text to copy
 * @param [options]
 * @param [options.toast] - A message to display when copying was successful
 */
function copyToClipboardAndShowToastMessage(text: string | (() => string), { toast = i18n('toast') } = {}) {
  copyToClipboard(typeof text === 'function' ? text() : text)
    .then(() => showToastMessage(toast, { type: 'success' }))
    // There is intentionally no option for the error message, because an error is largely an edge
    // case, and we should not bother team technical writing to come up with an error message for
    // each usage of this util.
    .catch(() => showToastMessage(i18n('toast.error'), { type: 'error' }));
}

export { copyToClipboardAndShowToastMessage as copyToClipboard };
