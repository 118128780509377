import { assert } from 'cadenza/utils/custom-error';

function copyValueToClipboard(input: HTMLInputElement | HTMLTextAreaElement) {
  assert(document.body.contains(input), 'The input element must be connected to the DOM');

  const activeElement = document.activeElement;
  const disabled = input.disabled;

  input.disabled = false;
  input.select();

  document.execCommand('copy');

  input.disabled = disabled;

  if (activeElement instanceof HTMLElement) {
    activeElement.focus();
  }
}

/**
 * Asynchronously (when the API is available) write the given text value to the clipboard.
 *
 * @param text - Text to write
 * @return A promise that is fulfilled when the text has been copied
 * @deprecated This is a low-level API. You'll typically want to use {@link import('ui/clipboard-button/clipboard-button').copyToClipboard|clipToClipboard()} instead
 * or a pre-defined clipboard button (see {@link import('ui/clipboard-button/clipboard-button').createClipboardButton|createClipboardButton()}).
 */
export function copyToClipboard(text: string) {
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text);
  } else {
    // The navigator.clipboard API is disabled when we're not in a secure context (e.g. https:// or localhost).
    // Workaround: create dummy input
    const input = document.createElement('input');
    input.value = text;
    document.body.append(input);
    copyValueToClipboard(input);
    input.remove();
    return Promise.resolve();
  }
}
