import h from 'hyperscript';
import iconRecord from '@disy/cadenza-icons/record.svg';
import iconStopwatch from '@disy/cadenza-icons/stopwatch.svg';
import iconRefresh from '@disy/cadenza-icons/refresh.svg';
import iconProfile from '@disy/cadenza-icons/profile.svg';
import iconLogout from '@disy/cadenza-icons/logout.svg';
import iconHelp from '@disy/cadenza-icons/help.svg';
import iconShortcuts from '@disy/cadenza-icons/shortcuts.svg';
import iconBulb from '@disy/cadenza-icons/bulb.svg';

import { Modal } from 'ui/dialog/modal/modal';
import 'ui/data/data';
import { setupMenuButton } from 'ui/menu/menu';

import { supportsKeyboard } from 'cadenza/utils/media';
import { icon } from 'cadenza/utils/icon/icon';
import { cadenzaUrl } from 'cadenza/utils/cadenza-url/cadenza-url';
import { showKeymap } from 'cadenza/hotkeys/showKeymap';
import { isFeatureAvailable } from 'cadenza/features';
import { openUrl } from 'cadenza/utils/open-url';
import { theme } from 'cadenza/theming-api';

import './topnav.css';
import i18n from './messages.properties';

class Topnav extends HTMLElement {
  constructor() {
    super();
    setupMenuButton(this, createMenuContent, { delegate: '.d-topnav-dropdown' });
  }

  connectedCallback() {
    this.classList.toggle('dark-bg', theme('main-header-invert'));
  }
}

function createMenuContent(menuButton) {
  const entries = menuButton.querySelector('d-data').value;
  const additionalContent = menuButton.querySelector('template');
  const content = entries.filter(({ id }) => id !== 'keymap' || supportsKeyboard).map(createEntry);
  if (menuButton.getAttribute('roundtrip-measurement-enabled') === 'true') {
    content.push({
      icon: icon(iconStopwatch),
      label: i18n('roundtripMeasure.label'),
      execute: showRoundTripMeasurement,
    });
  }
  if (menuButton.matches('.d-help-menu')) {
    if (isFeatureAvailable('WORKBOOK_PERFORMANCE_ANALYSIS')) {
      content.push({
        icon: icon(iconRecord),
        label: i18n('queryLogger'),
        execute: () => openUrl(cadenzaUrl('/query-logger')),
      });
    }
  }
  if (additionalContent) {
    content.push(additionalContent.content.cloneNode(true));
  }
  return content;
}

function createEntry({ id, printName, url, target }) {
  if (id === 'keymap') {
    return {
      icon: icon(iconShortcuts),
      label: printName,
      execute: showKeymap,
    };
  }
  return {
    icon: getIcon(id, url),
    label: printName,
    url: cadenzaUrl(url),
    target,
  };
}

function getIcon(id, url) {
  switch (id) {
    case 'help':
      return icon(url.startsWith('/help-learning/') ? iconBulb : iconHelp);
    case 'logout':
      return icon(iconLogout);
    case 'userprofile':
      return icon(iconProfile);
    case 'reloadRepository':
      return icon(iconRefresh);
  }
}

function showRoundTripMeasurement() {
  const dialog = new Modal({
    title: i18n('roundtripMeasure.label'),
    size: 's',
  });
  dialog.onclose = () => dialog.remove();
  const spinner = h('d-progress-spinner', { label: i18n('roundtripMeasure.loading') });

  startMeasurement()
    .then((roundTripTime) => {
      dialog.body.append(h('p', i18n('roundtripMeasure.result', { roundTripTime })));
    })
    .catch(() => {
      dialog.body.append(
        h('d-alert', {
          type: 'error',
          message: i18n('roundtripMeasure.error'),
        }),
      );
    })
    .finally(() => {
      spinner.remove();
    });

  dialog.body.append(spinner);
  document.body.append(dialog);
  dialog.showModal();
}

function startMeasurement() {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.addEventListener('load', () => {
      if (xhr.status === 200) {
        resolve(Date.now() - start);
      } else {
        reject();
      }
    });
    xhr.addEventListener('error', () => reject());
    xhr.open('GET', window.Disy.contextPath + '/status');
    const start = Date.now();
    xhr.send();
  });
}

customElements.define('d-topnav', Topnav);
