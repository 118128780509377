import type { Data } from 'ui/data/data';

import { assertNonNullable } from 'cadenza/utils/custom-error';
import { getConfiguration as fetchConfiguration } from 'cadenza/api-client/configuration-api';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface Configuration {
  // This type is augmented elsewhere.
}

export type ConfigurationName = keyof Configuration;

const configuration: Partial<Configuration> = {};
const fetchConfigurationPromises: Partial<Record<ConfigurationName, Promise<Configuration[ConfigurationName]>>> = {};

const PREFIX = 'config-';
export function initializeConfiguration() {
  document
    .querySelectorAll<Data<Configuration[ConfigurationName]>>(`d-data[id^=${PREFIX}]`)
    .forEach(<T extends ConfigurationName>(data: Data<Configuration[T]>) => {
      configuration[data.id.substring(PREFIX.length) as T] = data.value!;
    });
}

export function getConfiguration<C extends ConfigurationName>(configName: C) {
  const config = configuration[configName] as Configuration[C] | undefined;
  assertNonNullable(config, `Configuration "${configName}" is not initialized`);
  return config;
}

/**
 * Fetches one or more configuration objects from the backend and stores them under the configuration key.
 */
export const fetchConfigurations = async (configNames: ConfigurationName[]) => {
  const configEntries = await Promise.all(
    configNames
      .filter((configName) => configuration[configName] == null)
      .map(async (configName) => {
        if (!fetchConfigurationPromises[configName]) {
          fetchConfigurationPromises[configName] = fetchConfiguration(configName).finally(() => {
            delete fetchConfigurationPromises[configName];
          });
        }
        return [configName, await fetchConfigurationPromises[configName]];
      }),
  );
  Object.assign(configuration, Object.fromEntries(configEntries));
};

// for tests only
export function __setConfig__<T extends ConfigurationName>(configName: T, config: Configuration[T] | undefined) {
  configuration[configName] = config;
}
