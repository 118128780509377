import { ajax } from 'cadenza/api-client/api';
import { MIMETYPE_JSON } from 'cadenza/model/media-type';

export interface PurposeOfRequestFormConfiguration {
  fields: PurposeOfRequestFieldConfigurationDto[];
}

export interface PurposeOfRequestFieldConfigurationDto {
  printName: string;
  isMandatory: boolean;
  description: string;
  options?: string[];
}

export function getPurposeOfRequestFormConfiguration(): Promise<PurposeOfRequestFormConfiguration> {
  return ajax<PurposeOfRequestFormConfiguration>('/config/purpose-of-request', {
    type: 'GET',
    contentType: MIMETYPE_JSON,
  });
}

export function updatePurposeOfRequest(reason: string[]) {
  return ajax<void>(`/purpose-of-request?${new URLSearchParams(reason.map((v) => ['reason', v]))}`, {
    type: 'POST',
  });
}
